import './app.scss';
import 'lazysizes';
// import 'intersection-observer';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

(function () {
    // if you need react, just enable it by commenting in this line
    // react.init();

    const menu = document.getElementById('menu');
    if (menu) {
        import('./ts/navigation').then((navigation) => {
            navigation.default.init();
        });
    }

    const aosElements = document.querySelectorAll('[data-aos]');
    if (aosElements.length) {
        import('./ts/aos').then((aos) => {
            aos.default.init();
        });
    }

    const videos = document.querySelectorAll(
        'video'
    ) as NodeListOf<HTMLVideoElement>;
    if (videos.length) {
        import('./ts/video').then((videoElem) => {
            videos.forEach((component) => {
                videoElem.default.init(component);
            });
        });
    }

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll(
        '.swiper-container'
    ) as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        import('./ts/swiper').then((swiper) => {
            allSwiper.forEach(function (swiperElement) {
                if (!swiperElement.dataset.swiperOptions) return;
                // Set swiper element and swiper options from data-attribute
                swiper.default.init(
                    swiperElement,
                    JSON.parse(swiperElement.dataset.swiperOptions)
                );
            });
        });
    }

    // no element request because element will be generated via js
    // const userAgent = window.navigator.userAgent;
    // const isGoogleBot = /Googlebot/.test(userAgent);

    // if (isGoogleBot) {
    //     console.log('Google Bot detected, not initializing the cookie banner.');
    //     return;
    // } else {
    //     import('./ts/cookie-banner').then((cookieBanner) => {
    //         cookieBanner.default.init();
    //     });
    //     console.log('Cookie banner initialized.');
    // }

    const referenceSliders = document.querySelectorAll(
        '.references-slider'
    ) as NodeListOf<HTMLElement>;
    if (referenceSliders.length) {
        import('./ts/reference-slider').then((animatedSlider) => {
            referenceSliders.forEach((section) => {
                animatedSlider.default.init(section);
            });
        });
    }

    const caseStudiesGrid = document.querySelectorAll(
        '.case-studies-grid'
    ) as NodeListOf<HTMLElement>;
    if (caseStudiesGrid.length) {
        import('./ts/case-studies-grid').then((caseStudiesGridElem) => {
            caseStudiesGrid.forEach((section) => {
                caseStudiesGridElem.default.init(section);
            });
        });
    }

    const awardsSliders = document.querySelectorAll(
        '.awards-slider'
    ) as NodeListOf<HTMLElement>;
    if (awardsSliders.length) {
        import('./ts/awards-slider').then((animatedSlider) => {
            awardsSliders.forEach((section) => {
                animatedSlider.default.init(section);
            });
        });
    }

    const backgroundVideos = document.querySelectorAll(
        '.background-video'
    ) as NodeListOf<HTMLElement>;
    if (backgroundVideos.length) {
        import('./ts/background-video').then((backgroundVideo) => {
            backgroundVideos.forEach((container) => {
                backgroundVideo.default.init(container);
            });
        });
    }

    const imageVideoBanners = document.querySelectorAll(
        '.image-video-banner'
    ) as NodeListOf<HTMLElement>;
    if (imageVideoBanners.length) {
        import('./ts/image-video-banner').then((imageVideoBanner) => {
            imageVideoBanners.forEach((section) => {
                imageVideoBanner.default.init(section);
            });
        });
    }

    const sectionsBackground = document.querySelectorAll('section');
    if (sectionsBackground.length) {
        import('./ts/background-color').then((bgColor) => {
            bgColor.default.init();
        });
    }

    const iframeVideoSections = document.querySelectorAll(
        '.video-iframe'
    ) as NodeListOf<HTMLElement>;
    if (iframeVideoSections.length) {
        import('./ts/video-iframe').then((videoIframe) => {
            videoIframe.default.init();
        });
    }

    // Ensure TypeScript knows about userAgentData
    interface Navigator {
        userAgentData?: {
            brands: { brand: string; version: string }[];
            getHighEntropyValues: (hints: string[]) => Promise<{
                brands?: { brand: string; version: string }[];
                fullVersionList?: { brand: string; version: string }[];
            }>;
        };
    }

    async function browserHeadless(): Promise<boolean> {
        if (typeof navigator !== 'undefined') {
            // Check low entropy values first
            if (navigator.userAgentData && navigator.userAgentData.brands) {
                if (
                    navigator.userAgentData.brands.some((brand) =>
                        brand.brand.toLowerCase().includes('headless')
                    )
                ) {
                    return true;
                }
            }

            // Check high entropy values if available
            if (
                navigator.userAgentData &&
                navigator.userAgentData.getHighEntropyValues
            ) {
                const highEntropyValues =
                    await navigator.userAgentData.getHighEntropyValues([
                        'brands',
                        'fullVersionList'
                    ]);
                if (
                    highEntropyValues.brands &&
                    highEntropyValues.brands.some((brand) =>
                        brand.brand.toLowerCase().includes('headless')
                    )
                ) {
                    return true;
                }
                if (
                    highEntropyValues.fullVersionList &&
                    highEntropyValues.fullVersionList.some((brand) =>
                        brand.brand.toLowerCase().includes('headless')
                    )
                ) {
                    return true;
                }
            }

            // Traditional user agent check
            if (
                navigator.userAgent &&
                navigator.userAgent.toLowerCase().includes('headless')
            ) {
                return true;
            }
        }
        return false;
    }

    async function initApp() {
        // const isHeadless = true;
        const isHeadless = await browserHeadless();
        if (!isHeadless) {
            import('./ts/cookie-banner').then((cookieBanner) => {
                cookieBanner.default.init();
            });
        }
    }

    initApp();
})();
